<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <public-filter
          :action-methods="searchData"
          :action-filter="filterData"
          :reset-filter="resetSearch"
        />
      </b-card>
    </b-col>
    <b-col cols="12">
      <b-card no-body>
        <b-card-header>
          <b-card-title>Stok Listesi</b-card-title>
          <b-button
            variant="primary"
            to="/stocks/add"
          >
            Stok Ekle
          </b-button>
        </b-card-header>
        <b-table
          class="font-small-3"
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
          hover
        >
          <template #cell(brand)="data">
            <div>{{ data.item.brand }} {{ data.item.model }}</div>
            <div class="font-small-2 text-warning">
              Renk: {{ data.item.color }} - Model Yılı: {{ data.item.myear }}
            </div>
            <div class="text-muted font-small-2">
              {{ data.item.hardware }}
            </div>
          </template>
          <template #cell(stock_status)="data">
            <div v-if="data.item.stock_status">
              {{ data.item.stock_status }}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #cell(control)="data">
            <b-button
              variant="primary"
              size="sm"
              :to="'/stocks/edit/' + data.item.id"
            >
              Güncelle
            </b-button>
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import PublicFilter from '@/views/Sales/Stocks/Index/PublicFilter.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BCardHeader,
    BCardTitle,
    PublicFilter,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          key: 'brand',
          label: 'Araç Bilgisi',
        },
        {
          key: 'chasis',
          label: 'Şase',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'stock_status',
          label: 'Durum',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'username',
          label: 'EKLEYEN',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          'com_stock.id AS id',
          'com_brand.name AS brand',
          'com_model.title AS model',
          'com_color.title AS color',
          'com_stock.myear AS myear',
          'com_stock.chasis AS chasis',
          'com_stock.hardware AS hardware',
          'com_stock_statuses.title AS stock_status',
          'com_user.name AS username',
        ],
        order_by: ['com_stock.id', 'DESC'],
        where: {
          '_mssql_aracsatis.id': null,
        },
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['stock/getStocks']
    },
    dataCount() {
      return this.$store.getters['stock/getStocksCount']
    },
    quickSearch() {
      return this.$store.getters['stock/getQuickSearch']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      if (!this.dataQuery.where['com_stock.id_com_brand']) {
        if (['1', '2', '3', '4', '5', '6', '13'].includes(this.userData.id_com_brand)) {
          this.dataQuery.where['com_stock.id_com_brand'] = this.userData.id_com_brand
        }
      }
      this.$store.dispatch('stock/stocks', this.dataQuery)
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    searchData() {
      if (this.quickSearch.keyword.length > 0) {
        this.dataQuery.like = {
          'com_stock.chasis': this.quickSearch.keyword,
        }
        this.getDataList()
      }
    },
    resetSearch() {
      this.dataQuery.like = {}
      this.dataQuery.where = {}
      this.getDataList()
    },
    filterData(params) {
      const where = {}
      if (params.id_com_brand) {
        where['com_stock.id_com_brand'] = params.id_com_brand
      }
      this.dataQuery.where = where
      this.getDataList()
    },
  },
}
</script>

<style scoped>

</style>
